<template>
  <div class="demo-container">
    <button @click="$router.push('/menus')" @mouseover="backHover = true" @mouseleave="backHover = false"
            class="p-4 desktop-back-button back-button flex items-center">
      <IconBackArrow class="mr-2" :color="backHover ? '#E54325' : 'black'"/>
      Back to home
    </button>

    <img aria-hidden="true" alt="A delicious looking mojito" src="@/assets/demo/mojito.png" class="mojito shadowed"/>
    <img aria-hidden="true" alt="A fresh salad" src="@/assets/demo/salad.png" class="salad shadowed"/>
    <img aria-hidden="true" alt="A clean fork and knife" src="@/assets/demo/siverware.png" class="silverware"/>

    <div class="center-content flex flex-col justify-between h-full">
      <div class="spacer flex-grow" />
      <div class="flex flex-row try-it-now mt-8">
        <img aria-hidden="true" alt="Try it now" src="@/assets/demo/try-it-now.svg" class="try"/>
        <img aria-hidden="true" alt="An arrow pointing to the app" src="@/assets/demo/dotted-point-arrow.svg"
             class="arrow"/>
      </div>
      <div class="browser-frame shadowed">
        <div class="demo-banner">
          <button @click="$router.push('/menus')" class="flex items-center ml-4">
            <IconBackArrow color="white"/>
          </button>
          <span class="demo-banner-text">Demo</span>
          <div class="demo-banner-spacer"></div>
        </div>
        <div class="preloader-backdrop" >
          <loading-dots />
        </div>
        <iframe class="browser-site" src="../menus/dianas?demo=true"/>
        <img src="@/assets/demo/frame.png" class="browser-image" alt="iphone browser frame">
      </div>
      <div class="spacer flex-grow" />
    </div>
  </div>
</template>

<script>
import IconBackArrow from "@/components/icons/IconBackArrow";
import LoadingDots from "@/components/LoadingDots";
import {BodyLockService} from "@/services/BodyLockService";

export default {
  name: "Demo",
  components: {LoadingDots, IconBackArrow},
  data() {
    return {
      backHover: false
    }
  },
  mounted: function () {
    BodyLockService.lock()
  },
  destroyed: function () {
    BodyLockService.unlock()
  }
}
</script>

<style scoped>

.preloader-backdrop {
  top: 12px;
  left: 12px;
  height: calc(100% - 24px);
  width: calc(100% - 24px);
  border-radius: 24px;
  background: var(--gray-100);
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -10;
}

.try-it-now {
  width: 360px;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.demo-container {
  height: 100vh;
  width: 100vw;
  background: #D7E8E8;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: column;
  overflow-y: hidden;
}

.browser-frame {
  max-height: 720px;
  position: relative;
}

.browser-image {
  max-height: 720px;
  position: relative;
  pointer-events: none;
}

.shadowed {
  filter: drop-shadow(-4px 8px 24px rgba(71, 122, 128, 0.4));
}

.demo-banner {
  display: none;
}

.browser-site {
  transform: scale(0.85);
  position: absolute;
  top: 36px;
  left: -3px;
  height: calc(100% - 5px);
  width: calc(100% - -10px);
}

.mojito {
  position: absolute;
  transform: translateX(-550px) translateY(300px);
  max-height: 440px;
  pointer-events: none;
}

.salad {
  position: absolute;
  max-height: 640px;
  transform: translateX(-650px) translateY(-180px);
  pointer-events: none;
}

.silverware {
  position: absolute;
  transform: translateX(450px);
  max-height: 1000px;
  pointer-events: none;
}

.arrow {
  position: absolute;
  transform: translateX(64px) translateY(15px);
  pointer-events: none;
}

.back-button {
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  vertical-align: center;
  position: absolute;
  top: 35px;
  left: calc(50% - 400px);
}

.desktop-back-button:hover {
  color: #E54325;
}

@media screen and (max-width: 500px) {

  .shadowed {
    filter: unset;
  }

  .demo-banner {
    height: 40px;
    width: 100%;
    background: #E54325;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
  }

  .demo-banner-text {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    vertical-align: center;
    color: white;
  }

  .demo-banner-spacer {
    width: 48px;
    height: 100%;

  }

  .mojito, .silverware, .salad, .try, .arrow, .footer, .back-button, .spacer, .desktop-back-button, .try-it-now {
    display: none !important;
  }

  .demo-container {
    height: 100%;
    width: 100%;
    background: var(--gray-100);
  }

  .browser-frame {
    height: calc(100% - 40px);
    width: 100%;
    max-height: unset;
    margin-top: unset !important;
  }

  .iphone-notch-fill {
    display: none;
  }

  .browser-image {
    display: none;
  }

  .browser-site {
    transform: unset;
    height: 100%;
    width: 100%;
    left: unset;
    top: unset;
  }

  .center-content {
    width: 100%;
  }

  .demo-container {
    background: var(--gray-100);
  }

  .preloader-backdrop {
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: unset;
    background: var(--gray-100);
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: -10;
  }

}

</style>
