<template>
  <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><g :stroke="color" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="m19 12h-14"/><path d="m12 19-7-7 7-7"/></g></svg>
</template>

<script>
export default {
  name: "IconBackArrow",
  props: {
    color: {
      default: '#000000',
      type: String
    }
  }
}
</script>

<style scoped>

</style>
