<template>
    <svg id="dots"
         width="132px"
         height="58px"
         viewBox="0 0 132 58"
         xmlns="http://www.w3.org/2000/svg">
        <title>Loading Dots</title>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="dots" fill="#A3A3A3">
                <circle id="dot1" cx="25" cy="30" r="6"></circle>
                <circle id="dot2" cx="55" cy="30" r="6"></circle>
                <circle id="dot3" cx="85" cy="30" r="6"></circle>
            </g>
        </g>
    </svg>
</template>

<script>
    export default {
        name: "LoadingDots"
    }
</script>

<style scoped>

    #dots > circle {
        transform-origin: center!important;
    }

    #dots #dot1{
        animation: load 1s infinite ease-in-out;
    }

    #dots #dot2{
        animation: load 1s infinite ease-in-out;
        animation-delay: 0.3s;
    }

    #dots #dot3{
        animation: load 1s infinite ease-in-out;
        animation-delay: 0.4s;
    }

    @keyframes load{
        0%{
            opacity: 0;
        }
        50%{
            opacity: 1;
        }
        100%{
            opacity: 0;
        }
    }

</style>
